// be wary of adding new imports here (especially hub-http), as it can break quick
// fetch for consumers, be sure to test against the record or another quick fetch
// consumer

import quickFetch from 'quick-fetch';
export const getCardLocationQueryUrl = ({
  objectTypeId,
  objectId,
  location
}) => {
  return `/crm-record-cards/v3/record-cards/${objectTypeId}/for-object/${objectId}/for-location/${location}`;
};
export const getCrmLocationQuickFetchKey = ({
  objectTypeId,
  objectId,
  location
}) => `crm-location-quick-fetch:REST:${location}:${objectTypeId}:${objectId}`;
export const quickFetchCrmLocation = queryVariables => {
  const url = quickFetch.getApiUrl(getCardLocationQueryUrl(queryVariables), true);
  const quickFetchKey = getCrmLocationQuickFetchKey(queryVariables);
  quickFetch.makeEarlyRequest(quickFetchKey, {
    url,
    timeout: 5000,
    dataType: 'json'
  });
};